<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { UrlHelper, CssHelpers } from "./code/utils";
    import { GlAccountEnum, GlEntrySourceEnum, UserBalanceWarningStatus } from "./code/consts.generated";

    import type { IUserBasicData } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";
    import JobStatusPanel from "./JobStatusPanel.svelte";
    import type { IRoomData } from "./code/backend/nonauthenticated.generated.types";
    import SortIcon from "./SortIcon.svelte";

    export let services: IServices;
    export const page_params: any = {};

    interface IRoomDataEx extends IRoomData {
        selected: boolean;
    }
    function isIntersection(s1: string[], s2: string[]): boolean {
        for (const x1 of s1) {
            for (const x2 of s2) {
                if (x1 == x2) {
                    return true;
                }
            }
        }
        return false;
    }

    interface IUserBasicDataEx extends IUserBasicData {
        id_numeric: number;
    }
    let users: IUserBasicData[] = [];
    let is_loading: boolean = false;
    let job_uuid: string = "";
    let job_title: string = "";
    let rooms: IRoomDataEx[] = [];
    let role_room_map: Map<string, IRoomData> = new Map();

    let only_active: boolean = true;
    let filter_str: string = "";
    let users_selected: IUserBasicDataEx[] = [];

    type SortColumn =  "username" | "title" | "id" | "email";
    let sort_column: SortColumn = "id";
    let sort_asc: boolean = true;

    async function load() {
        is_loading = true;
        const res_rooms = await services.backend
            .nonauthenticated()
            .getOffices({});
        rooms = res_rooms.rooms.map<IRoomDataEx>((x) => ({
            code: x.code,
            id: x.id,
            role_name: x.role_name,
            selected: true,
            title: x.title,
        }));
        for (const room of rooms) {
            role_room_map.set(room.role_name, room);
        }
        users = (await services.backend.admin().getUsers({})).users;
        is_loading = false;
        filter();
    }
    function filter(): void {
        let res = users;
        if (only_active) {
            res = res.filter((x) => x.active);
        }
        if (filter_str.length > 0) {
            const f = filter_str.toLocaleLowerCase();
            res = res.filter(
                (x) =>
                    x.username.toLocaleLowerCase().indexOf(f) >= 0 ||
                    x.title.toLocaleLowerCase().indexOf(f) >= 0 ||
                    x.email.toLocaleLowerCase().indexOf(f) >= 0
            );
        }
        const selected_room_roles = rooms
            .filter((x) => x.selected)
            .map((x) => x.role_name);
        // don't apply any filtering if no room selected - just show all collected so far
        if (selected_room_roles.length > 0) {
            res = res.filter((x) => isIntersection(x.roles, selected_room_roles));
        }
        users_selected = res.map(x => ({
            ...x,
            id_numeric: parseFloat(x.user_uuid)
        }));
    }
    onMount(async () => {
        await load();
    });
    function getUserStatusCssClass(status: string): string {
        status = status.trim();
        if (status == UserBalanceWarningStatus.Ok) return "w3-green-lite";
        if (status == UserBalanceWarningStatus.Warning) return "w3-orange-lite";
        return "w3-red-lite";
    }
    async function onRecalcStatuses(): Promise<void> {
        const res = await services.backend
            .admin()
            .recalculateAllUserStatuses({});
        job_uuid = res.job_uuid;
        job_title = "Preračunavanje statusov";
    }
    function onCopyEmails(): void {
        let text = users_selected.map((x) => x.email).join(" ");
        navigator.clipboard
            .writeText(text)
            .catch((err) => console.error("Async: Could not copy text: ", err));
    }
    function getRoomTitles(roles: string[]): IRoomData[] {
        const res: IRoomData[] = [];
        for (const role of roles) {
            const room = role_room_map.get(role);
            if (room != undefined) {
                res.push(room);
            }
        }
        return res;
    }
    function toggleRoomFilter(room_role: string): void {
        for (const room of rooms) {
            if (room_role == room.role_name) {
                // room.selected = !room.selected;
            }
        }
        filter();
    }
    function sort() {
        const sort_factor = sort_asc ? 1 : -1;
        if (sort_column == "username") {
            users_selected = users_selected.sort((a,b) => sort_factor * a.username.localeCompare(b.username));
        } else if (sort_column == "title") {
            users_selected = users_selected.sort((a,b) => sort_factor * a.title.localeCompare(b.title));
        } else if (sort_column == "id") {
            users_selected = users_selected.sort((a,b) => sort_factor * (a.id_numeric - b.id_numeric));
        } else if (sort_column == "email") {
            users_selected = users_selected.sort((a,b) => sort_factor * a.email.localeCompare(b.email));
        }
    }
    function sortClick(col: SortColumn): void {
        if (col == sort_column) {
            sort_asc = !sort_asc;
        } else {
            sort_column = col;
            sort_asc = true;
        }
        sort();
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <button class="w3-button-link" on:click={() => UrlHelper.openUserNew()}>
            Nov uporabnik
        </button>
        <button class="w3-button-link" on:click={() => onRecalcStatuses()}>
            Preračunaj statuse
        </button>
        <button class="w3-button-link" on:click={() => onCopyEmails()}>
            Kopiraj e-naslove
        </button>
    </div>
    <div class="w3-row">
        <div class="w3-col w3-padding-large">
            <input
                class="w3-margin"
                type="text"
                placeholder="Filter"
                bind:value={filter_str}
                on:keyup={() => filter()}
            />
            <label class="switch w3-hide-small">
                <input
                    type="checkbox"
                    bind:checked={only_active}
                    on:change={() => filter()}
                />
                <span class="slider round" />
            </label>
            <span class="w3-hide-small">Samo aktivni</span>
            {#each rooms as room}
                <label
                    class="w3-round w3-padding-small w3-nowrap {CssHelpers.getCssClassForRoomId(
                        room.id
                    )}"
                >
                    {room.title}
                    <input
                        type="checkbox"
                        bind:checked={room.selected}
                        on:change={() => toggleRoomFilter(room.role_name)}
                    />
                </label>
            {/each}
        </div>
    </div>
    <div class="w3-row">
        <div class="w3-col m12 l10 w3-padding-large w3-center">
            <JobStatusPanel {services} uuid={job_uuid} title={job_title} />
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if !is_loading}
                <span>{users_selected.length} zapisov</span>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th style="min-width: 65px">
                            Id
                            <SortIcon
                                target_sort_col={"id"}
                                sort_col={sort_column} sort_asc={sort_asc}
                                onClick={() => sortClick("id") }
                                />
                        </th>
                        <th>
                            Ime
                            <SortIcon
                                target_sort_col={"username"}
                                sort_col={sort_column} sort_asc={sort_asc}
                                onClick={() => sortClick("username") }
                                />
                        </th>
                        <th>
                            Naziv
                            <SortIcon
                                target_sort_col={"title"}
                                sort_col={sort_column} sort_asc={sort_asc}
                                onClick={() => sortClick("title") }
                                />
                        </th>
                        <th>
                            Email
                            <SortIcon
                                target_sort_col={"email"}
                                sort_col={sort_column} sort_asc={sort_asc}
                                onClick={() => sortClick("email") }
                                />
                        </th>
                        <th>Aktiven</th>
                        <th>Popust</th>
                        <th>Status</th>
                        <th>Pisarne</th>
                    </tr>
                    {#each users_selected as user}
                        <tr>
                            <td>
                                {user.user_uuid}
                            </td>
                            <td>
                                <a
                                    href={UrlHelper.getOpenUserUrl(
                                        user.user_uuid
                                    )}
                                >
                                    {user.username}
                                </a>
                            </td>
                            <td>
                                {user.title}
                                {#if user.is_admin}
                                    <span class="w3-round w3-padding-small w3-red-lite">admin</span>
                                {/if}
                                {#if user.is_power}
                                    <span class="w3-round w3-padding-small w3-orange-lite">power</span>
                                {/if}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {user.active ? "da" : "ne"}
                            </td>
                            <td>
                                {user.discount_factor}
                            </td>
                            <td class={getUserStatusCssClass(user.status)}>
                                {user.status}
                            </td>
                            <td>
                                {#each getRoomTitles(user.roles) as room}
                                    <span
                                        class="w3-round w3-padding-small {CssHelpers.getCssClassForRoomId(
                                            room.id
                                        )}"
                                    >
                                        {room.title}
                                    </span>
                                {/each}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
