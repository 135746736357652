<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import SvelteMarkdown from "svelte-markdown";
    import {
        enable_wide_gui,
        is_admin,
        is_power,
        is_logged_in,
        user_data,
    } from "./code/stores";

    import type { IRoomData } from "./code/backend/nonauthenticated.generated.types";
    import {
        TIME_RANGES,
        getStartOfWeek,
        niceTimeNoSec,
        dayString,
        PseudoRandom,
        UrlHelper,
        niceNumber,
        niceDateTimeNoSec,
        niceDate,
        CssHelpers,
        sum,
    } from "./code/utils";
    import { VISIBLE_DAY_OFFSET, VISIBLE_HOURS_PER_DAY } from "./code/bl";
    import type { IReservationView, IAddButtonWrapper } from "./code/bl";
    import type { IServices } from "./code/services";
    import PropertyList from "./PropertyList.svelte";
    import type { PropertyListItem } from "./code/gui_classes";
    import LoaderSmall from "./LoaderSmall.svelte";
    import { UserBalanceWarningStatus } from "./code/consts.generated";
    import type { IMessageRec } from "./code/backend/users.generated.types";

    export let services: IServices;
    export let page_params: any = {};

    let last_params: string = "";
    let week_offset: number = 0;
    let current_office: number = -1;
    let current_color: string = "w3-sand";
    let count: number = -1;
    let offices: IRoomData[] = [];
    let reservations: IReservationView[] = [];
    let reservations_load_token: number;
    const random_generator = new PseudoRandom(0);
    let today_x_offset: number = 0;
    let now_y_offset: number = 0;
    let week_start_day: Date = new Date();
    let week_end_day: Date = new Date();
    let buffer_height_percent: number = (100 * 0.25) / VISIBLE_HOURS_PER_DAY;
    let add_buttons: IAddButtonWrapper[] = [];

    let day_titles: string[] = [];
    let day_titles_small: string[] = [];
    let msgs: IMessageRec[] = [];

    let status: string = "";
    let can_insert: boolean = false;
    let can_edit: boolean = false;
    let is_loading: boolean = false;
    let is_msgs_loading: boolean = false;

    let week_amt_room: number = 0;
    let week_h_room: number = 0;
    let week_amt_all: number = 0;
    let week_h_all: number = 0;

    let allow_wide_screen: boolean = $enable_wide_gui;
    enable_wide_gui.subscribe((val) => {
        allow_wide_screen = val;
    });
    let cssClassRow = $enable_wide_gui ? "tdCalendarRow" : "tdCalendarRowSmall";
    let cssClassRow2 = $enable_wide_gui
        ? "tdCalendarRow2"
        : "tdCalendarRow2Small";

    ////////////////////////////////////
    let show_modal_info: boolean = false;
    let selected_reservation: IReservationView | null = null;
    let selected_reservation_props: PropertyListItem[] = [];
    ////////////////////////////////////

    is_logged_in.subscribe(async () => {
        await load();
    });
    user_data.subscribe(async () => {
        status = $user_data != null ? $user_data.status : "";
        await load();
    });

    onMount(async () => {
        offices = await services.cache.getRooms();
        const tks_index =
            "" + (offices.filter((x) => x.code == "TKS")[0]?.id ?? -1);
        current_office = parseInt(page_params.current_office ?? tks_index, 10);
        week_offset = parseInt(page_params.week_offset ?? "0", 10);
        await load();
    });
    beforeUpdate(async () => {
        if (last_params != JSON.stringify(page_params)) {
            await load();
        }
    });

    async function incWeek(): Promise<void> {
        week_offset++;
        setUrlHash();
        await load();
    }
    async function decWeek(): Promise<void> {
        week_offset--;
        setUrlHash();
        await load();
    }
    async function resetWeek(): Promise<void> {
        week_offset = 0;
        setUrlHash();
        await load();
    }

    async function setMessageAsRead(id: string) {
        await services.backend.users().markMessageAsReadByCurrentUser({ message_id: id });
        await load();
    }

    async function setCurrentOffice(id: number) {
        current_office = id;
        setUrlHash();
        await load();
    }

    function getWeekdayDate(day_offset: number): string {
        const ts = week_start_day.getTime();
        const d = new Date(ts + day_offset * TIME_RANGES.DAY);
        return `${d.getDate()}.${d.getMonth() + 1}.`;
    }

    function getWeekdayDateSmall(day_offset: number): string {
        const ts = week_start_day.getTime();
        const d = new Date(ts + day_offset * TIME_RANGES.DAY);
        return `${d.getDate()}.`;
    }

    function addNew(data: IAddButtonWrapper): void {
        UrlHelper.newReservation(
            current_office,
            week_offset,
            data.day_offset,
            data.hour
        );
    }

    function openEditReservation(id: number): void {
        if (can_edit) {
            UrlHelper.editReservation(id);
        }
    }

    const MODAL_INFO_ID = "id02";
    function showDetailsBox(res: IReservationView): void {
        if ($is_logged_in && (res.is_mine || $is_admin || $is_power)) {
            show_modal_info = true;
            selected_reservation = res;
            selected_reservation_props = getSelectedReservationProperties();
            document.getElementById(MODAL_INFO_ID).style.display = "block";
        }
    }
    function hideDetailsBox(): void {
        show_modal_info = false;
        document.getElementById(MODAL_INFO_ID).style.display = "none";
    }

    function openNew(): void {
        UrlHelper.newReservation(current_office, week_offset);
    }

    function prepareDateHeaders(): void {
        day_titles = [];
        day_titles_small = [];
        for (let i = 0; i < 7; i++) {
            const title = dayString(i).substring(0, 3);
            day_titles.push(title + " " + getWeekdayDate(i));
            day_titles_small.push(
                // title.substring(0, 1) + " " + getWeekdayDateSmall(i)
                getWeekdayDate(i)
            );
        }
    }

    function setUrlHash(): void {
        UrlHelper.openHome(current_office, week_offset);
    }

    function getSelectedReservationProperties(): PropertyListItem[] {
        const res: PropertyListItem[] = [];
        if (selected_reservation == null) return res;
        if (selected_reservation.is_mine || $is_admin) {
            res.push({
                title: $is_admin ? "Uporabnik" : "Opis",
                value: selected_reservation.text,
                onClick: $is_admin
                    ? (async () => {
                        const { users } = await services.backend
                            .admin()
                            .getUsers({});
                        const hit = users.find(
                            (x) => x.username == selected_reservation.text
                        );
                        if (hit != undefined) {
                            UrlHelper.openUser(hit.user_uuid);
                        }
                    })
                    : undefined,
            });
        }
        res.push({
            title: "Trajanje (h)",
            value: niceNumber(selected_reservation.length, 2),
        });
        res.push({
            title: "Cena",
            value: niceNumber(selected_reservation.price, 2),
        });
        res.push({ title: "Datum", value: selected_reservation.date_s });
        res.push({ title: "Začetek", value: selected_reservation.start_s });
        res.push({ title: "Konec", value: selected_reservation.end_s });
        res.push({ title: "Vnešeno", value: selected_reservation.entered_s });
        res.push({
            title: "Skupina",
            value: selected_reservation.is_group ? "Da" : "Ne",
        });
        return res;
    }

    async function load() {
        is_loading = true;
        is_msgs_loading = true;
        reservations = [];
        const token = random_generator.next();
        reservations_load_token = token;
        last_params = JSON.stringify(page_params);

        if (last_params != JSON.stringify(page_params)) {
            setUrlHash();
        }

        week_start_day = getStartOfWeek(new Date(), week_offset);
        week_end_day = getStartOfWeek(new Date(), week_offset + 1);
        prepareDateHeaders();

        const now = new Date();
        const day_index = 1 + ((now.getDay() + 6) % 7);
        if (week_offset == 0) {
            today_x_offset = (100 * (1 + ((now.getDay() + 6) % 7))) / 8;
            now_y_offset =
                (100 *
                    (now.getHours() -
                        VISIBLE_DAY_OFFSET +
                        now.getMinutes() / 60)) /
                VISIBLE_HOURS_PER_DAY;
        } else {
            // hide today indicator
            today_x_offset = -100;
        }

        const rooms = await services.cache.getAllowedRooms(
            $user_data?.roles ?? []
        );

        // also check if user has status OK
        can_insert =
            $is_logged_in &&
            rooms.some((x) => x.id == current_office) &&
            status != UserBalanceWarningStatus.NoInsert;
        can_edit = $is_logged_in;

        add_buttons = [];
        if ($is_logged_in && can_insert && week_offset >= 0) {
            for (let i = 1; i <= 7; i++) {
                if (week_offset == 0 && i < day_index) {
                    continue;
                }
                const hour_index = now.getHours() - VISIBLE_DAY_OFFSET;
                let x_offset_percent = (100 * i) / 8;
                for (let j = 0; j < 24 - VISIBLE_DAY_OFFSET; j++) {
                    if (week_offset == 0 && i == day_index && j <= hour_index) {
                        continue;
                    }
                    let y_offset_percent = (100 * j) / VISIBLE_HOURS_PER_DAY;
                    add_buttons.push({
                        height_percent: 100 / VISIBLE_HOURS_PER_DAY,
                        x_offset_percent,
                        y_offset_percent,
                        day_offset: i - 1,
                        hour: j + VISIBLE_DAY_OFFSET,
                    });
                }
            }
        }

        current_color = CssHelpers.getCssClassForRoomId(current_office);

        if ($is_logged_in) {

            const { messages } = await services.backend.users().getMessagesForCurrentUser({
                include_read: false
            });
            msgs = messages;
            is_msgs_loading = false;

            const res = await services.backend
                .reservations()
                .getWeek({ id_room: current_office, week_offset: week_offset });
            // guard for race-condition in loading
            if (reservations_load_token != token) {
                return;
            }
            count = res.reservations.length;
            reservations = res.reservations.map<IReservationView>((x) => {
                const start_d = new Date(x.start);
                const length = (x.end - x.start) / TIME_RANGES.HOUR;
                const x_offset_percent =
                    (100 * (1 + ((start_d.getDay() + 6) % 7))) / 8;
                const y_offset_percent =
                    (100 *
                        (start_d.getHours() -
                            VISIBLE_DAY_OFFSET +
                            start_d.getMinutes() / 60)) /
                    VISIBLE_HOURS_PER_DAY;
                return {
                    date_s: niceDate(new Date(x.start)),
                    end: x.end,
                    end_s: niceTimeNoSec(new Date(x.end)),
                    entered: 0,
                    entered_s: niceDateTimeNoSec(new Date(x.entered)),
                    start: x.start,
                    start_s: niceTimeNoSec(new Date(x.start)),
                    length,
                    x_offset_percent,
                    y_offset_percent,
                    height_percent: (100 * length) / VISIBLE_HOURS_PER_DAY,
                    text: x.text,
                    is_mine: x.is_mine,
                    can_edit: x.can_edit,
                    id: x.id,
                    is_group: x.is_group,
                    price: x.price,
                };
            });
            if ($is_admin) {
                const { reservations } = await services.backend
                    .admin()
                    .getReservations({
                        from: week_start_day.getTime(),
                        to: week_end_day.getTime()
                    });
                const reservations_room = reservations.filter(
                    (x) => x.room_id == current_office
                );
                week_amt_all = sum(reservations.map((x) => x.ex_price));
                week_h_all = sum(reservations.map((x) => x.len));
                week_amt_room = sum(reservations_room.map((x) => x.ex_price));
                week_h_room = sum(reservations_room.map((x) => x.len));
            }
        } else {
            is_msgs_loading = false;
            msgs = [];
            const res = await services.backend
                .nonauthenticated()
                .getWeek({ id_room: current_office, week_offset: week_offset });
            // guard for race-condition in loading
            if (reservations_load_token != token) {
                return;
            }
            count = res.reservations.length;
            reservations = res.reservations.map<IReservationView>((x) => {
                const start_d = new Date(x.start);
                const length = (x.end - x.start) / TIME_RANGES.HOUR;
                const x_offset_percent =
                    (100 * (1 + ((start_d.getDay() + 6) % 7))) / 8;
                const y_offset_percent =
                    (100 *
                        (start_d.getHours() -
                            VISIBLE_DAY_OFFSET +
                            start_d.getMinutes() / 60)) /
                    VISIBLE_HOURS_PER_DAY;
                return {
                    date_s: niceDate(new Date(x.start)),
                    end: x.end,
                    end_s: niceTimeNoSec(new Date(x.end)),
                    entered: 0,
                    entered_s: niceDateTimeNoSec(new Date(0)),
                    start: x.start,
                    start_s: niceTimeNoSec(new Date(x.start)),
                    length,
                    x_offset_percent,
                    y_offset_percent,
                    height_percent: (100 * length) / VISIBLE_HOURS_PER_DAY,
                    text: "Zasedeno",
                    can_edit: false,
                    is_mine: false,
                    id: -1,
                    is_group: false,
                    price: 0,
                };
            });
        }
        is_loading = false;
    }
</script>

{#if msgs.length > 0}
    <div class="w3-container">
        <div class="w3-row">
            <div class="w3-col l3 m2 s12">&nbsp;</div>
            <div class="w3-col l6 m8 s12 w3-panel w3-round">
                {#each msgs as msg, i}
                    <div class="w3-panel w3-light-gray w3-padding w3-round">
                        <h3>{msg.title}</h3>
                        <div class="small-gray">Objavljeno: {niceDate(msg.created_at)}</div>
                        <SvelteMarkdown source={msg.text} />
                        <div class="w3-center">
                            <button
                                class="w3-button w3-round-large w3-theme-action right-margin-small"
                                on:click={() => setMessageAsRead(msg.id)}
                            >
                                Označi kot prebrano
                            </button>
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
{/if}

{#if msgs.length == 0 && !is_msgs_loading}
    <div class="w3-container w3-theme-light">
        {#if status == UserBalanceWarningStatus.NoInsert}
            <div class="w3-panel w3-deep-orange w3-round">
                Ups, izgleda, da zadnji račun ni bil poravnan, zato trenutno ni
                možen vnos novih rezervacij. <br />
                Najbolje, da to čimprej uredite, v primeru napake pa nas le pokličite.
            </div>
        {/if}

        {#if status == UserBalanceWarningStatus.Warning}
            <div class="w3-panel w3-deep-orange w3-round">
                Hej, samo da vas spomnimo na plačilo računa - bilo bi super, da to
                čimprej uredite.
            </div>
        {/if}

        <!-- small screens -->

        <div class="w3-row w3-hide-medium w3-hide-large w3-padding {current_color}">
            <div class="w3-col l12 m12 s12 w3-right-align">
                {#each offices as office, i}
                    <button
                        class="w3-button w3-round-large {office.id == current_office
                            ? 'w3-theme-action'
                            : 'w3-theme-light'} right-margin-small"
                        on:click={() => setCurrentOffice(office.id)}
                    >
                        {office.title}
                    </button>
                {/each}
            </div>
        </div>

        <div class="w3-row w3-hide-medium w3-hide-large w3-padding {current_color}">
            <div class="w3-col l12 m12 s12 w3-right-align">
                {#if can_insert}
                    <button
                        class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                        title="Vnesi novo rezervacijo"
                        on:click={() => openNew()}>&#43;</button
                    >
                {/if}
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Prejšnji teden"
                    on:click={() => decWeek()}>&#5130;</button
                >
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Naslednji teden"
                    on:click={() => incWeek()}>&#5125;</button
                >
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Trenutni teden"
                    on:click={() => resetWeek()}
                >
                    <img src="/img/icons8-home-24.svg" height="20" alt="home" />
                </button>
            </div>
        </div>

        <!-- medium and big screens -->

        <div class="w3-row w3-hide-small w3-padding {current_color}">
            <div class="w3-col l8 m8 s12">
                {#each offices as office, i}
                    <button
                        class="w3-button w3-round-large {office.id == current_office
                            ? 'w3-theme-action'
                            : 'w3-theme-light'} w3-margin-right"
                        on:click={() => setCurrentOffice(office.id)}
                    >
                        {office.title}
                    </button>
                {/each}
                <LoaderSmall visible={is_loading} />
                {#if $is_admin}
                    <div>
                        Št. ur: {week_h_room} ● Cena: {week_amt_room} ● Št. ur skupaj:
                        {week_h_all} ● Cena skupaj: {week_amt_all}
                    </div>
                {/if}
            </div>
            <div class="w3-col l4 m4 s12 w3-right-align">
                {#if can_insert}
                    <button
                        class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                        title="Vnesi novo rezervacijo"
                        on:click={() => openNew()}>&#43;</button
                    >
                {/if}
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Prejšnji teden"
                    on:click={() => decWeek()}>&#5130;</button
                >
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Naslednji teden"
                    on:click={() => incWeek()}>&#5125;</button
                >
                <button
                    class="w3-button w3-theme-light w3-hover-theme w3-round-large light-border"
                    title="Trenutni teden"
                    on:click={() => resetWeek()}
                >
                    <img src="/img/icons8-home-24.svg" height="20" alt="home" />
                </button>
            </div>
        </div>

        <div
            id="tabSingleWeek"
            class="ui-tabs-panel ui-widget-content ui-corner-bottom"
        >
            <div class="tdCalendarRowHeader">
                <div class="tdCalendarHeader">&nbsp;</div>
                {#each day_titles as day_title, i}
                    <div class="tdCalendarHeader">
                        <span class="w3-hide-medium w3-hide-large spanDayNameSmall">
                            {day_titles_small[i]}</span
                        ><span class="w3-hide-small spanDayName"> {day_title}</span>
                    </div>
                {/each}
            </div>
            <div id="divCalendar" style="width: 100%; position: relative">
                <div class={cssClassRow}>6:00</div>
                <div class={cssClassRow2}>7:00</div>
                <div class={cssClassRow}>8:00</div>
                <div class={cssClassRow2}>9:00</div>
                <div class={cssClassRow}>10:00</div>
                <div class={cssClassRow2}>11:00</div>
                <div class={cssClassRow}>12:00</div>
                <div class={cssClassRow2}>13:00</div>
                <div class={cssClassRow}>14:00</div>
                <div class={cssClassRow2}>15:00</div>
                <div class={cssClassRow}>16:00</div>
                <div class={cssClassRow2}>17:00</div>
                <div class={cssClassRow}>18:00</div>
                <div class={cssClassRow2}>19:00</div>
                <div class={cssClassRow}>20:00</div>
                <div class={cssClassRow2}>21:00</div>
                <div class={cssClassRow}>22:00</div>
                <div class={cssClassRow2}>23:00</div>

                {#each add_buttons as add_button, i}
                    <div
                        class="tdHourDiv"
                        on:click={() => addNew(add_button)}
                        style="left: {add_button.x_offset_percent}%; top: {add_button.y_offset_percent}%; height: {add_button.height_percent}%;"
                    >
                        <i>&#43;</i>
                    </div>
                {/each}

                {#each reservations as res, i}
                    <div
                        class="tdBuffer"
                        style="left: {res.x_offset_percent}%; top: {res.y_offset_percent -
                            buffer_height_percent}%; height: {buffer_height_percent}%;"
                    />
                {/each}
                {#each reservations as res, i}
                    <div
                        class="tdBuffer"
                        style="left: {res.x_offset_percent}%; top: {res.y_offset_percent +
                            res.height_percent}%; height: {buffer_height_percent}%;"
                    />
                {/each}

                {#each reservations as res, i}
                    <div
                        class={(res.is_mine
                            ? res.can_edit
                                ? "tdBusyMine"
                                : "tdBusyMineFixed"
                            : "tdBusyOther") + (res.is_group ? " tdGroup" : "")}
                        style="left: {res.x_offset_percent}%; top: {res.y_offset_percent}%; height: {res.height_percent}%;"
                        on:click={() => showDetailsBox(res)}
                    >
                        {res.start_s} - {res.end_s} <br />
                        {res.text}
                    </div>
                {/each}

                <div class="tdToday" style="left: {today_x_offset}%;" />
                <div
                    class="tdNow"
                    style="left: {today_x_offset}%; top: {now_y_offset}%"
                />
            </div>
        </div>
    </div>
{/if}
<div class="w3-container">
    <div id={MODAL_INFO_ID} class="w3-modal">
        <div class="w3-modal-content">
            <header class="w3-container">
                <span
                    on:click={() => hideDetailsBox()}
                    class="w3-button w3-display-topright">&times;</span
                >
                <h2>Podrobnosti rezervacije</h2>
            </header>

            <div class="w3-container">
                <PropertyList items={selected_reservation_props} />
            </div>

            <footer class="w3-container w3-theme-l2 w3-padding w3-right-align">
                {#if selected_reservation?.can_edit}
                    <span
                        on:click={() =>
                            openEditReservation(selected_reservation?.id)}
                        class="w3-btn w3-theme-action w3-round-large"
                        >Uredi</span
                    >
                {/if}
                <span
                    on:click={() => hideDetailsBox()}
                    class="w3-btn w3-theme-light w3-round-large">Zapri</span
                >
            </footer>
        </div>
    </div>
</div>

<style>
    /********************************************************************************************/

    .tdCalendarRowHeader {
        height: 22px;
        width: 100%;
        background-color: #eee;
    }

    .tdCalendarHeader {
        background-color: #eee;
        /*#445566;*/
        color: #444;
        /*font-weight: bold;*/
        /*width: 85px;*/
        width: 12.5%;
        text-align: center;
        /*padding-top: 2px;*/
        margin: 0px;
        /*height: 29px;*/
        float: left;
        vertical-align: middle;
    }

    .tdBusyMineFixed,
    .tdBusyMine,
    .tdBusyOther,
    .divFloatItem,
    .tdBusyUnknown,
    .tdBusyOverlap {
        z-index: 20;
        position: absolute;
        top: 20px;
        left: 12.5%;
        /*85px;*/
        width: 12.3%;
        /*83px;*/
        height: 38px;
        vertical-align: middle;
        text-align: center;
        border: solid 1px #222222;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: x-small;
    }

    .tdHourDiv,
    .tdHourDiv:hover {
        width: 12.5%;
        z-index: 12;
        position: absolute;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: x-small;
    }

    .tdHourDiv > i {
        display: none;
        margin-top: 0px;
        font-size: 20px;
        color: #ccc;
    }

    .tdHourDiv:hover {
        background-color: #d9f3c5;
        border: 1px solid #ccc;
    }

    .tdHourDiv:hover > i {
        display: block;
    }

    .tdToday {
        z-index: 10;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 12.5%;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        border: solid 0px #222222;
        border-width: 0px 1px 1px 1px;
        background-color: #ffff00;
        opacity: 0.2;
        /* filter: alpha(opacity=20); */
    }

    .tdNow {
        z-index: 111;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 12.5%;
        height: 2px;
        /*border: solid 0px #222222;
    border-width: 0px 1px 1px 1px;*/
        border: none;
        background-color: #e80c4d;
        opacity: 0.8;
        /* filter: alpha(opacity=80); */
    }

    .tdDateTime {
        width: 160px;
    }

    .no-border {
        border: none;
    }

    .tdBusyUnknown {
        background-color: #8b60a5;
        /*#445566;*/
        border: 0 none;
        color: White;
    }

    .tdBusyOther {
        background-color: #eee;
        /*#445566;*/
        color: #333;
    }

    .tdBusyOverlap {
        background-color: #838e99;
        /*#445566;*/
    }

    .tdBusyMine {
        background-color: #91f38a;
        /*#88aacc;*/
        color: Black;
    }

    .tdBusyMineFixed {
        background-color: #f8a846;
        /*#888888;*/
        color: Black;
    }

    .tdGroup {
        border: 3px solid #666;
    }

    .tdCanEdit {
        cursor: pointer;
    }

    .tdCalendarRow,
    .tdListRow2,
    .divRow {
        background-color: #ffffff;
        /* border-top: #f4f4f4 1px solid; */
        /*#fffaaa;*/
    }

    .tdCalendarRow2,
    .tdCalendarRow2Small,
    .tdListRow,
    .divRowAlter {
        background-color: #f4f4f4;
        /* #F5E8FF;*/
        /*#fff888;*/
    }

    .tdCalendarRow2,
    .tdCalendarRow {
        height: 39px;
        vertical-align: middle;
    }
    @media screen and (max-height: 850px) {
        .tdCalendarRow2,
        .tdCalendarRow {
            height: 29px;
        }
    }
    .tdCalendarRow2Small,
    .tdCalendarRowSmall {
        height: 29px;
        vertical-align: middle;
    }

    .tdBuffer {
        z-index: 15;
        position: absolute;
        width: 12.3%;
        vertical-align: middle;
        text-align: center;
        border: solid 1px #222222;
        background-color: #000000;
        opacity: 0.2;
    }

    .w3-modal {
        z-index: 3000;
    }

    .light-border {
        border: #ccc solid 1px;
    }
    .right-margin-small {
        margin-right: 3px;
    }
</style>
