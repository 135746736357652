<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";

    import type { ISingleReservation } from "./code/backend/reservations.generated.types";
    import {
        username,
        is_admin,
        is_power,
        is_short,
        user_data,
    } from "./code/stores";
    import { USER_ROLES } from "./code/consts.generated";
    import type { IRoomData } from "./code/backend/nonauthenticated.generated.types";
    import {
        getStartOfWeek,
        TIME_RANGES,
        UrlHelper,
        toLocalDateTimeString, NumberParserSi
    } from "./code/utils";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export let page_params: any = {};

    let reservation_uuid: string = "";
    let reservation: ISingleReservation | null = null;
    let room: IRoomData | null = null;
    let is_new: boolean = true;
    let is_loading: boolean = false;
    let is_saving: boolean = false;
    let last_params: string = "";
    let manual_price: string = "-1";
    let parser = new NumberParserSi();

    let is_error: boolean = false;
    let error_msg: string = "";
    let is_success: boolean = false;

    let target_date: string = new Date()
        .toLocaleDateString("sl-SI")
        .slice(0, 10);
    const hours = createHours();
    let hour = hours.filter((x) => x.text == "16:00")[0];
    const lengths = createLengths();
    let length = lengths.filter((x) => x.id == "1")[0];
    const repetitions = createRepetitions();
    let repetition = repetitions.filter((x) => x.id == "1")[0];

    function createHours(): Array<{ id: string; text: string }> {
        let res = [
            { id: "6", text: "06:00" },
            { id: "6.25", text: "06:15" },
            { id: "6.5", text: "06:30" },
            { id: "6.75", text: "06:45" },
            { id: "7", text: "07:00" },
            { id: "7.25", text: "07:15" },
            { id: "7.5", text: "07:30" },
            { id: "7.75", text: "07:45" },
            { id: "8", text: "08:00" },
            { id: "8.25", text: "08:15" },
            { id: "8.5", text: "08:30" },
            { id: "8.75", text: "08:45" },
            { id: "9", text: "09:00" },
            { id: "9.25", text: "09:15" },
            { id: "9.5", text: "09:30" },
            { id: "9.75", text: "09:45" },
            { id: "10", text: "10:00" },
            { id: "10.25", text: "10:15" },
            { id: "10.5", text: "10:30" },
            { id: "10.75", text: "10:45" },
            { id: "11", text: "11:00" },
            { id: "11.25", text: "11:15" },
            { id: "11.5", text: "11:30" },
            { id: "11.75", text: "11:45" },
            { id: "12", text: "12:00" },
            { id: "12.25", text: "12:15" },
            { id: "12.5", text: "12:30" },
            { id: "12.75", text: "12:45" },
            { id: "13", text: "13:00" },
            { id: "13.25", text: "13:15" },
            { id: "13.5", text: "13:30" },
            { id: "13.75", text: "13:45" },
            { id: "14", text: "14:00" },
            { id: "14.25", text: "14:15" },
            { id: "14.5", text: "14:30" },
            { id: "14.75", text: "14:45" },
            { id: "15", text: "15:00" },
            { id: "15.25", text: "15:15" },
            { id: "15.5", text: "15:30" },
            { id: "15.75", text: "15:45" },
            { id: "16", text: "16:00" },
            { id: "16.25", text: "16:15" },
            { id: "16.5", text: "16:30" },
            { id: "16.75", text: "16:45" },
            { id: "17", text: "17:00" },
            { id: "17.25", text: "17:15" },
            { id: "17.5", text: "17:30" },
            { id: "17.75", text: "17:45" },
            { id: "18", text: "18:00" },
            { id: "18.25", text: "18:15" },
            { id: "18.5", text: "18:30" },
            { id: "18.75", text: "18:45" },
            { id: "19", text: "19:00" },
            { id: "19.25", text: "19:15" },
            { id: "19.5", text: "19:30" },
            { id: "19.75", text: "19:45" },
            { id: "20", text: "20:00" },
            { id: "20.25", text: "20:15" },
            { id: "20.5", text: "20:30" },
            { id: "20.75", text: "20:45" },
            { id: "21", text: "21:00" },
            { id: "21.25", text: "21:15" },
            { id: "21.5", text: "21:30" },
            { id: "21.75", text: "21:45" },
            { id: "22", text: "22:00" },
            { id: "22.25", text: "22:15" },
            { id: "22.5", text: "22:30" },
            { id: "22.75", text: "22:45" },
            { id: "23", text: "23:00" },
        ];
        return res;
    }
    function createRepetitions(): Array<{ id: string; text: string }> {
        let res = [
            { id: "1", text: "1-krat" },
            { id: "2", text: "2-krat" },
            { id: "3", text: "3-krat" },
            { id: "4", text: "4-krat" },
            { id: "5", text: "5-krat" },
            { id: "6", text: "6-krat" },
            { id: "7", text: "7-krat" },
            { id: "8", text: "8-krat" }
        ];
        return res;
    }
    function createLengths(): Array<{ id: string; text: string }> {
        let res = [
            { id: "0.25", text: "15min" },
            { id: "0.5", text: "30min" },
            { id: "1", text: "1h" },
            { id: "1.25", text: "1h 15min" },
            { id: "1.5", text: "1h 30min" },
            { id: "1.75", text: "1h 45 min" },
            { id: "2", text: "2h" },
            { id: "2.25", text: "2h 15min" },
            { id: "2.5", text: "2h 30 min" },
            { id: "2.75", text: "2h 45min" },
            { id: "3", text: "3h" },
            { id: "3.25", text: "3h 15min" },
            { id: "3.5", text: "3h 30min" },
            { id: "3.75", text: "3h 45min" },
            { id: "4", text: "4h" },
            { id: "4.25", text: "4h 15min" },
            { id: "4.5", text: "4h 30min" },
            { id: "4.75", text: "4h 45min" },
            { id: "5", text: "5h" },
            { id: "5.5", text: "5h 30min" },
            { id: "6", text: "6h" },
            { id: "6.5", text: "6h 30min" },
            { id: "7", text: "7h" },
            { id: "8", text: "8h" },
            { id: "9", text: "9h" },
            { id: "10", text: "10h" },
            { id: "11", text: "11h" },
            { id: "12", text: "12h" },
            { id: "13", text: "13h" },
            { id: "14", text: "14h" },
            { id: "15", text: "15h" },
            { id: "16", text: "16h" },
        ];
        if (!$is_admin) {
            res = res.filter((x) => x.id != "0.25");
            if (!$is_power && !$is_short) {
                res = res.filter((x) => x.id != "0.5");
            }
        }
        return res;
    }

    async function load() {
        try {
            is_error = false;
            last_params = JSON.stringify(page_params);
            const rooms = await services.cache.getAllowedRooms(
                $user_data.roles
            );
            // edit: page_params = { id }
            // new: page_params = { current_office, week_offset, day_offset, hour }
            if (page_params.id == undefined) {
                is_new = true;
                let id_room = page_params.current_office;

                let room_hits = rooms.filter((x) => x.id == id_room);
                if (room_hits.length == 0) {
                    console.warn("Napaka pri iskanju lokacije.");
                    room_hits = rooms;
                }
                room = room_hits[0];
                const week = parseInt(page_params.week_offset ?? "0");
                const dow = parseInt(page_params.day_offset ?? "0");
                let h = parseInt(page_params.hour ?? "16");
                if (h < 6) {
                    h = 6;
                } else if (h > 23) {
                    h = 23;
                }
                hour = hours.filter((x) => x.id == "" + h)[0];
                let xtarget_date = getStartOfWeek(new Date(), week);
                xtarget_date.setDate(xtarget_date.getDate() + dow);
                xtarget_date.setHours(h);
                target_date = xtarget_date.toISOString().slice(0, 10);
                reservation = {
                    id_room: room.id,
                    is_group: false,
                    len: 1,
                    manual_price: null,
                    price: 0,
                    target_date: xtarget_date.getTime(),
                    text: "",
                    username: "",
                };
            } else {
                is_new = false;
                is_loading = true;
                reservation_uuid = page_params.id;
                const { data } = await services.backend
                    .reservations()
                    .getSingleReservation({ id: reservation_uuid });
                reservation = data;
            }
            room = rooms.filter((x) => x.id == reservation.id_room)[0] ?? null;
            is_loading = false;
            const d = new Date(reservation.target_date);
            target_date = toLocalDateTimeString(d).slice(0, 10);
            const target_hour = "" + (d.getHours() + d.getMinutes() / 60);
            hour =
                hours.filter((x) => x.id == target_hour)[0] ??
                hours.filter((x) => x.id == "16:00")[0];
            const ls = "" + reservation.len;
            length =
                lengths.filter((x) => x.id == ls)[0] ??
                lengths.filter((x) => x.id == "1")[0];
            manual_price =
                reservation.manual_price == null
                    ? ""
                    : "" + reservation.manual_price;
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
    }
    onMount(async () => {
        // await load();
    });
    beforeUpdate(async () => {
        if (last_params != JSON.stringify(page_params)) {
            await load();
        }
    });

    async function deleteReservation(): Promise<void> {
        if (!confirm("Ste prepričani, da želite zbrisati rezervacijo?")) {
            return;
        }
        is_error = false;
        is_saving = true;
        try {
            // do some string and numeric gymnastics to get correct date and time
            await services.backend.reservations().deleteReservation({
                id: reservation_uuid,
            });
            is_success = true;
            UrlHelper.openHome(room.id, getFinalWeekOffset());
        } catch (err) {
            error_msg = err.message;
            is_error = true;
        }
        is_saving = false;
    }
    function returnWithoutChanges() {
        UrlHelper.openHome(room.id, getFinalWeekOffset());
    }
    async function saveData(): Promise<void> {
        is_error = false;
        is_saving = true;
        try {
            // do some string and numeric gymnastics to get correct date and time
            const hx = parseFloat(hour.id);
            const h = Math.floor(hx);
            const min = Math.floor(60 * (hx - h));
            let s = "" + (min + 100 * h + 10000);
            s =
                target_date +
                "T" +
                s.substring(1, 3) +
                ":" +
                s.substring(3, 5) +
                ":00";
            const d = new Date(s);

            validateData(d);
            if (is_new) {
                await services.backend.reservations().insertReservation({
                    description: reservation.text,
                    id_room: room.id,
                    is_group: reservation.is_group,
                    len: parseFloat(length.id),
                    repetitions: parseFloat(repetition.id),
                    target_date: d.getTime(),
                });
            } else {
                let mp: number | undefined = undefined;
                if (manual_price != "") {
                    const mp2 = parser.parse(manual_price);
                    if (!isNaN(mp2) && mp2 >= 0) {
                        mp = mp2;
                    }
                }
                let username: string | undefined = undefined;
                if ($is_admin || $is_power) {
                    username = reservation.username;
                }
                await services.backend.reservations().updateReservation({
                    description: reservation.text,
                    id: reservation_uuid,
                    is_group: reservation.is_group,
                    len: parseFloat(length.id),
                    target_date: d.getTime(),
                    manual_price: mp,
                    username,
                });
            }
            is_success = true;
            UrlHelper.openHome(room.id, getFinalWeekOffset());
        } catch (err) {
            error_msg = err.message;
            is_error = true;
        }
        is_saving = false;
    }
    function validateData(d: Date): void {
        if (d < new Date() && !$is_admin && !is_power) {
            throw new Error("Datum ne more biti v preteklosti");
        }
    }
    function getFinalWeekOffset() {
        const d0 = getStartOfWeek(new Date(), 0).getTime();
        const d1 = getStartOfWeek(new Date(target_date), 0).getTime();
        return Math.round((d1 - d0) / TIME_RANGES.WEEK);
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        {#if is_success}
            <div class="w3-panel w3-pale-green w3-border">
                <h3>Podatki uspešno shranjeni</h3>
                <p>
                    <a href="/#/home/{room?.id ?? -1}/{getFinalWeekOffset()}"
                        >Nazaj</a
                    >
                </p>
            </div>
        {/if}
        {#if !is_success}
            {#if is_error}
                <div class="w3-panel w3-pale-yellow w3-border">
                    <h3>Opozorilo:</h3>
                    <p>{error_msg}</p>
                </div>
            {/if}
            <div class="w3-center">
                <Loader visible={!is_error && is_loading} />
            </div>
            <div class="w3-centered">
                <Loader visible={!is_error && is_saving} />
            </div>
            {#if (!is_error || is_new) && !is_loading && reservation != null}
                <div class="w3-container w3-theme-l3">
                    <h2>
                        {is_new ? "Vnos" : "Poprava"} rezervacije - {room?.title}
                    </h2>
                </div>

                <form class="w3-container">
                    <div class="w3-panel">
                        <label for="inDate">Datum</label>
                        <input
                            id="inDate"
                            class="w3-input w3-border w3-round"
                            type="date"
                            bind:value={target_date}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inHour">Ura</label>
                        <select
                            id="inHour"
                            class="w3-select w3-border w3-round"
                            bind:value={hour}
                        >
                            {#each hours as hour_option}
                                <option value={hour_option}>
                                    {hour_option.text}
                                </option>
                            {/each}
                        </select>
                    </div>

                    <div class="w3-panel">
                        <label for="inLen">Trajanje</label>
                        <select
                            id="inLen"
                            class="w3-select w3-border w3-round"
                            bind:value={length}
                        >
                            {#each lengths as length_option}
                                <option value={length_option}>
                                    {length_option.text}
                                </option>
                            {/each}
                        </select>
                    </div>

                    <div class="w3-panel">
                        <input
                            id="inGroup"
                            class="w3-check"
                            type="checkbox"
                            bind:checked={reservation.is_group}
                        />
                        <label for="inGroup">Skupina?</label>
                        <div class="small-gray">
                            * Označite to polje, če bo to srečanje skupine
                        </div>
                    </div>

                    <div class="w3-panel">
                        <label for="inDesc">Opis (opcijsko)</label>
                        <div class="small-gray">
                            * V to polje ne vpisujte občutljivih osebnih
                            podatkov
                        </div>
                        <input
                            id="inDesc"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={reservation.text}
                        />
                    </div>

                    {#if is_new}
                    <div class="w3-panel">
                        <label for="inHour">Število tedenskih ponovitev</label>
                        <select
                            id="inHour"
                            class="w3-select w3-border w3-round"
                            bind:value={repetition}
                        >
                            {#each repetitions as repetitions_option}
                                <option value={repetitions_option}>
                                    {repetitions_option.text}
                                </option>
                            {/each}
                        </select>
                    </div>
                    {/if}

                    {#if ($is_admin || $is_power) && !is_new}
                        <div class="w3-panel">
                            <label for="inUsername">Uporabniško ime</label>
                            <input
                                id="inUsername"
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={reservation.username}
                            />
                        </div>
                        <div class="w3-panel">
                            <label for="inManualPrice">Ročna cena</label>
                            <div class="small-gray">
                                * Prazno polje ali -1 pomeni brez ročne cene,
                                sicer se upošteva vnešena vrednost.
                            </div>
                            <input
                                id="inManualPrice"
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={manual_price}
                            />
                        </div>
                    {/if}
                </form>
                <div class="w3-panel w3-right-align">
                    {#if !is_new}
                        <button
                            class="w3-btn w3-theme-danger w3-round-xlarge w3-left"
                            on:click={() => deleteReservation()}>Zbriši</button
                        >
                    {/if}
                    <button
                        class="w3-btn w3-theme-action w3-round-xlarge"
                        on:click={() => saveData()}>Shrani</button
                    >
                    <button
                        class="w3-btn w3-theme-light w3-round-xlarge"
                        on:click={() => returnWithoutChanges()}>Nazaj</button
                    >
                </div>
            {/if}
        {/if}
    </div>
</div>
